import { render, staticRenderFns } from "./newsListDetail.vue?vue&type=template&id=16c2f305&scoped=true&"
import script from "./newsListDetail.vue?vue&type=script&lang=js&"
export * from "./newsListDetail.vue?vue&type=script&lang=js&"
import style0 from "./newsListDetail.vue?vue&type=style&index=0&id=16c2f305&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16c2f305",
  null
  
)

export default component.exports